import { LineChart } from 'lucide-react';
import { MarketChart } from '@/components/market/MarketChart';
import { MarketNews } from '@/components/market/MarketNews';

export default function MarketSentiment() {
  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <LineChart className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold">Market Sentiment</h1>
          <p className="text-muted-foreground">Market analysis and predictions</p>
        </div>
      </div>

      <div className="grid gap-8 lg:grid-cols-[1fr_330px]">
        <MarketChart />
        
        <div className="lg:sticky lg:top-32 lg:h-[calc(100vh-8rem)]">
          <MarketNews />
        </div>
      </div>
    </div>
  );
}