import { useEffect, useState } from 'react';
import { Search, ChevronLeft, ChevronRight, TrendingUp, ExternalLink, Clock, ArrowUpDown } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { XMLParser } from 'fast-xml-parser';
import { cn } from '@/lib/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface TrendingItem {
  title: string;
  traffic: string;
  trafficNumber: number;
  picture: string;
  pictureSource: string;
  newsItems: Array<{
    title: string;
    url: string;
    source: string;
  }>;
  pubDate: string;
  timestamp: number;
}

type TimeFilter = '4h' | '24h' | '48h' | '7d';

export default function GoogleTrends() {
  const [trends, setTrends] = useState<TrendingItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>('24h');
  const [loadingMore, setLoadingMore] = useState(false);
  const [sortByTraffic, setSortByTraffic] = useState(true);
  const itemsPerPage = 20;

  useEffect(() => {
    fetchTrends();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [timeFilter]);

  const parseTrafficNumber = (traffic: string): number => {
    const match = traffic.match(/(\d+)\+/);
    if (match) {
      const number = parseInt(match[1], 10);
      if (traffic.includes('K')) return number * 1000;
      if (traffic.includes('M')) return number * 1000000;
      return number;
    }
    return 0;
  };

  const filterTrendsByTime = (items: TrendingItem[]) => {
    const now = Date.now();
    const filterTime = {
      '4h': 4 * 60 * 60 * 1000,
      '24h': 24 * 60 * 60 * 1000,
      '48h': 48 * 60 * 60 * 1000,
      '7d': 7 * 24 * 60 * 60 * 1000
    }[timeFilter];

    const filteredItems = items.filter(item => 
      (now - new Date(item.pubDate).getTime()) <= filterTime
    );

    if (sortByTraffic) {
      return filteredItems.sort((a, b) => b.trafficNumber - a.trafficNumber);
    } else {
      return filteredItems.sort((a, b) => b.timestamp - a.timestamp);
    }
  };

  const fetchTrends = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const proxyUrl = 'https://api.allorigins.win/raw?url=';
      
      // Fetch from multiple regions
      const regions = ['US', 'GB', 'CA', 'AU', 'IN'];
      const trendPromises = regions.map(region => 
        fetch(proxyUrl + encodeURIComponent(`https://trends.google.com/trends/trendingsearches/daily/rss?geo=${region}`))
          .then(res => res.text())
      );

      // Also fetch realtime trends
      const realtimeTrendUrl = 'https://trends.google.com/trends/api/realtimetrends?hl=en-US&tz=-60&cat=all&fi=0&fs=0&geo=US&ri=300&rs=20&sort=0';
      trendPromises.push(
        fetch(proxyUrl + encodeURIComponent(realtimeTrendUrl))
          .then(res => res.text())
      );
      
      const responses = await Promise.all(trendPromises);
      
      const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: "",
        textNodeName: "$",
        attributesGroupName: "@_",
        cdataTagName: "__cdata",
        processEntities: true,
        htmlEntities: true,
        parseAttributeValue: true,
        trimValues: true
      });

      let allItems: TrendingItem[] = [];

      // Parse RSS feeds
      responses.slice(0, -1).forEach(text => {
        try {
          const data = parser.parse(text);
          if (!data?.rss?.channel?.item) return;

          const items = (Array.isArray(data.rss.channel.item) ? data.rss.channel.item : [data.rss.channel.item])
            .filter(Boolean)
            .map((item: any) => {
              try {
                const traffic = item['ht:approx_traffic']?.$ || item['ht:approx_traffic'] || '0+ searches';
                const trafficNumber = parseTrafficNumber(traffic);
                const pubDate = new Date(item.pubDate?.$ || item.pubDate || new Date().toISOString());
                
                return {
                  title: item.title?.$ || item.title || 'Untitled',
                  traffic,
                  trafficNumber,
                  picture: item['ht:picture']?.$ || item['ht:picture'] || '',
                  pictureSource: item['ht:picture_source']?.$ || item['ht:picture_source'] || '',
                  newsItems: Array.isArray(item['ht:news_item']) 
                    ? item['ht:news_item'].map((news: any) => ({
                        title: news['ht:news_item_title']?.$ || news['ht:news_item_title'] || '',
                        url: news['ht:news_item_url']?.$ || news['ht:news_item_url'] || '',
                        source: news['ht:news_item_source']?.$ || news['ht:news_item_source'] || ''
                      }))
                    : [item['ht:news_item']].filter(Boolean).map((news: any) => ({
                        title: news['ht:news_item_title']?.$ || news['ht:news_item_title'] || '',
                        url: news['ht:news_item_url']?.$ || news['ht:news_item_url'] || '',
                        source: news['ht:news_item_source']?.$ || news['ht:news_item_source'] || ''
                      })),
                  pubDate: pubDate.toISOString(),
                  timestamp: pubDate.getTime()
                };
              } catch (err) {
                console.warn('Error parsing trend item:', err);
                return null;
              }
            })
            .filter(Boolean);

          allItems = [...allItems, ...items];
        } catch (err) {
          console.warn('Error parsing RSS feed:', err);
        }
      });

      // Parse realtime trends
      try {
        const realtimeText = responses[responses.length - 1];
        const realtimeData = JSON.parse(realtimeText.substring(realtimeText.indexOf('{')));
        
        const realtimeTrends = realtimeData.storySummaries?.trendingStories?.map((story: any) => ({
          title: story.title || 'Untitled',
          traffic: `${Math.round(story.entityNames[0]?.entityTraffic || 0)}K+ searches`,
          trafficNumber: (story.entityNames[0]?.entityTraffic || 0) * 1000,
          picture: story.image?.imgUrl || '',
          pictureSource: 'Google Trends',
          newsItems: story.articles?.map((article: any) => ({
            title: article.articleTitle,
            url: article.url,
            source: article.source
          })) || [],
          pubDate: new Date().toISOString(),
          timestamp: Date.now()
        })) || [];

        allItems = [...allItems, ...realtimeTrends];
      } catch (err) {
        console.warn('Error parsing realtime trends:', err);
      }

      // Remove duplicates based on title similarity
      const uniqueItems = allItems.reduce((acc: TrendingItem[], item) => {
        const isDuplicate = acc.some(existing => 
          existing.title.toLowerCase().includes(item.title.toLowerCase()) ||
          item.title.toLowerCase().includes(existing.title.toLowerCase())
        );
        if (!isDuplicate) {
          acc.push(item);
        } else {
          // Keep the one with higher traffic
          const existingIndex = acc.findIndex(existing => 
            existing.title.toLowerCase().includes(item.title.toLowerCase()) ||
            item.title.toLowerCase().includes(existing.title.toLowerCase())
          );
          if (existingIndex !== -1 && item.trafficNumber > acc[existingIndex].trafficNumber) {
            acc[existingIndex] = item;
          }
        }
        return acc;
      }, []);

      setTrends(uniqueItems);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching trends:', error);
      setError('Failed to load trends. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (newPage: number) => {
    setLoadingMore(true);
    setCurrentPage(newPage);
    await new Promise(resolve => setTimeout(resolve, 300));
    setLoadingMore(false);
  };

  const timeFilterOptions = [
    { value: '4h', label: 'Past 4hr' },
    { value: '24h', label: 'Past 24hr' },
    { value: '48h', label: 'Past 48hr' },
    { value: '7d', label: 'Past 7d' }
  ];

  const filteredTrends = filterTrendsByTime(trends);
  const totalPages = Math.ceil(filteredTrends.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTrends = filteredTrends.slice(startIndex, endIndex);

  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <Search className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold text-white">Google Trends</h1>
          <p className="text-muted-foreground">Latest trending searches and topics</p>
        </div>
        <div className="ml-auto flex items-center gap-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setSortByTraffic(!sortByTraffic)}
            className={cn(
              "border-primary/20",
              sortByTraffic && "bg-primary/10 text-primary"
            )}
          >
            <ArrowUpDown className="mr-2 h-4 w-4" />
            Sort by {sortByTraffic ? 'Traffic' : 'Time'}
          </Button>

          <Select
            value={timeFilter}
            onValueChange={(value) => setTimeFilter(value as TimeFilter)}
          >
            <SelectTrigger className="w-[140px] border-primary/20">
              <Clock className="mr-2 h-4 w-4 text-primary" />
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {timeFilterOptions.map(option => (
                <SelectItem 
                  key={option.value} 
                  value={option.value}
                  className="text-white hover:text-primary"
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Button 
            onClick={fetchTrends}
            className="bg-gradient-to-r from-yellow-500 to-green-500 text-white hover:from-yellow-600 hover:to-green-600"
          >
            Refresh Trends
          </Button>
        </div>
      </div>

      {error && (
        <Card className="border-destructive/50 p-4 text-center text-destructive">
          {error}
        </Card>
      )}

      <div className={cn("grid gap-4", loadingMore && "opacity-50 transition-opacity duration-200")}>
        {loading ? (
          <div className="flex h-[400px] items-center justify-center">
            <div className="h-8 w-8 animate-spin rounded-full border-2 border-primary border-t-transparent" />
          </div>
        ) : currentTrends.length > 0 ? (
          currentTrends.map((trend, index) => (
            <Card key={index} className="group overflow-hidden bg-black/40 backdrop-blur-sm transition-all duration-300 hover:bg-black/60 hover:shadow-[0_0_30px_rgba(255,170,0,0.2)]">
              <div className="flex items-start gap-6 p-6">
                {trend.picture && (
                  <div className="relative aspect-square w-24 flex-shrink-0 overflow-hidden rounded-xl">
                    <img 
                      src={trend.picture}
                      alt={trend.title}
                      className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.style.display = 'none';
                      }}
                    />
                  </div>
                )}
                <div className="flex-1 space-y-4">
                  <div className="flex items-baseline justify-between gap-4">
                    <h2 className="text-xl font-semibold text-white group-hover:text-primary">{trend.title}</h2>
                    <div className="flex items-center gap-2 whitespace-nowrap text-sm">
                      <TrendingUp className="h-4 w-4 text-primary" />
                      <span className="font-medium text-primary">{trend.traffic}</span>
                    </div>
                  </div>

                  {trend.newsItems && trend.newsItems.length > 0 && (
                    <div className="grid gap-2">
                      {trend.newsItems.map((news, idx) => (
                        <a
                          key={idx}
                          href={news.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="group/link flex items-start justify-between gap-4 rounded-lg border border-white/10 bg-black/20 p-3 transition-colors hover:border-primary/50 hover:bg-black/40"
                        >
                          <h3 className="flex-1 font-medium text-white group-hover/link:text-primary">{news.title}</h3>
                          <div className="flex items-center gap-2">
                            <span className="text-sm text-white/60">{news.source}</span>
                            <ExternalLink className="h-4 w-4 text-white/60 opacity-0 transition-opacity group-hover/link:opacity-100" />
                          </div>
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Card>
          ))
        ) : (
          <Card className="p-8 text-center text-white">
            No trends available for the selected time period.
          </Card>
        )}
      </div>

      {filteredTrends.length > 0 && (
        <div className="flex items-center justify-center gap-4">
          <Button
            variant="outline"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1 || loadingMore}
            className="gap-2 text-white"
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </Button>
          <span className="min-w-[100px] text-center text-sm text-white">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="outline"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || loadingMore}
            className="gap-2 text-white"
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  );
}