import { NavLink } from 'react-router-dom';
import { 
  Home,
  Newspaper,
  LineChart,
  Coins,
  Star,
  MessageSquare,
  BookOpen,
  Search
} from 'lucide-react';
import { cn } from '@/lib/utils';

const mainNavigation = [
  { name: 'Dashboard', href: '/', icon: Home },
  { name: 'Stories', href: '/stories', icon: Newspaper },
  { name: 'Market Sentiment', href: '/market-sentiment', icon: LineChart },
  { name: 'Tokens', href: '/tokens', icon: Coins },
  { name: 'Favorites', href: '/favorites', icon: Star },
  { name: 'Blurts', href: '/blurts', icon: MessageSquare },
  { name: 'Blog', href: '/blog', icon: BookOpen },
  { name: 'Google Trends', href: '/google-trends', icon: Search },
];

interface NavigationProps {
  onClose?: () => void;
}

export default function Navigation({ onClose }: NavigationProps) {
  return (
    <nav className="p-4">
      <div className="space-y-1">
        {mainNavigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              cn(
                'flex items-center rounded-lg px-3 py-2 text-sm font-medium',
                isActive
                  ? 'bg-gradient-to-r from-[#FFAA00]/20 to-[#CA5003]/20 text-[#FFAA00]'
                  : 'text-muted-foreground hover:bg-accent hover:text-accent-foreground'
              )
            }
            onClick={onClose}
          >
            <item.icon className="mr-3 h-5 w-5" />
            {item.name}
          </NavLink>
        ))}
      </div>
    </nav>
  );
}