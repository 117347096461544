import { XMLParser } from 'fast-xml-parser';

const parser = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: "@_",
  allowBooleanAttributes: true,
  textNodeName: "text",
  ignoreDeclaration: true,
  processEntities: true,
  htmlEntities: true
});

// List of CORS proxies to try in order
const CORS_PROXIES = [
  'https://corsproxy.io/?',
  'https://api.allorigins.win/raw?url=',
  'https://api.codetabs.com/v1/proxy?quest='
];

async function tryProxies(url: string): Promise<Response> {
  let lastError;
  
  for (const proxy of CORS_PROXIES) {
    try {
      const response = await fetch(proxy + encodeURIComponent(url));
      if (response.ok) {
        return response;
      }
    } catch (error) {
      lastError = error;
      continue;
    }
  }
  
  throw lastError || new Error('All proxies failed');
}

async function fetchFeed(url: string) {
  try {
    // Try direct fetch first
    try {
      const directResponse = await fetch(url);
      if (directResponse.ok) {
        const text = await directResponse.text();
        return parseFeedContent(text);
      }
    } catch (directError) {
      console.debug('Direct fetch failed, trying proxies:', directError);
    }

    // If direct fetch fails, try proxies
    const response = await tryProxies(url);
    const text = await response.text();
    return parseFeedContent(text);
  } catch (error) {
    console.error('Feed error:', url, error);
    throw error;
  }
}

function parseFeedContent(text: string) {
  // Try parsing as JSON first
  try {
    const data = JSON.parse(text);
    
    // Handle RSS.app JSON format
    if (data.status === 'ok' && Array.isArray(data.items)) {
      return data.items.map(processRSSItem);
    }
    
    // Handle other JSON feed formats
    if (Array.isArray(data)) {
      return data.map(processRSSItem);
    }
    
    // Handle single item JSON format
    if (data.title && data.link) {
      return [processRSSItem(data)];
    }
    
    throw new Error('Invalid JSON feed structure');
  } catch (jsonError) {
    // If JSON parsing fails, try XML
    try {
      const data = parser.parse(text);
      const channel = data.rss?.channel;
      if (!channel?.item) {
        throw new Error('Invalid XML feed structure');
      }
      const items = Array.isArray(channel.item) ? channel.item : [channel.item];
      return items.map(processRSSItem).filter(Boolean);
    } catch (xmlError) {
      console.warn('Failed to parse feed as XML:', xmlError);
      throw new Error('Failed to parse feed content');
    }
  }
}

export function processRSSItem(item: any) {
  if (!item) return null;

  try {
    // Extract image from various possible locations
    const image = item.enclosure?.["@_url"] ||
      item["media:content"]?.["@_url"] ||
      item["media:thumbnail"]?.["@_url"] ||
      (typeof item.image === 'string' ? item.image : item.image?.url) ||
      (item.description?.match(/<img[^>]*src="([^">]+)"/) || [])[1];

    // Clean up description text
    const description = item.description?.text || 
      item.description?.replace(/<[^>]*>/g, '') || 
      item.summary?.text ||
      item.summary?.replace(/<[^>]*>/g, '') || 
      '';

    // Generate a unique ID
    const id = item.guid?.text || 
      item.guid || 
      item.link?.text ||
      item.link || 
      `${item.title}-${new Date(item.pubDate || item.date_published || 0).getTime()}`;

    // Handle RSS.app specific fields
    const pubDate = item.pubDate?.text || 
      item.pubDate || 
      item.date_published || 
      item.created || 
      new Date().toISOString();

    const link = item.link?.text || 
      item.link || 
      item.url || 
      '';

    const title = item.title?.text || 
      item.title || 
      'Untitled';

    const source = item.source?.["#text"] || 
      item.source?.text ||
      (link ? new URL(link).hostname : '') ||
      'Unknown Source';

    const creator = item["dc:creator"]?.text || 
      item["dc:creator"] || 
      item.author || 
      item.creator || 
      'Unknown';

    return {
      id,
      title,
      link,
      pubDate,
      description,
      image,
      source,
      creator
    };
  } catch (error) {
    console.warn('Error processing RSS item:', error);
    return null;
  }
}

export { fetchFeed };