import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'next-themes';
import Layout from '@/components/Layout';
import Dashboard from '@/pages/Dashboard';
import Admin from '@/pages/Admin';
import BitcoinNews from '@/pages/BitcoinNews';
import Stories from '@/pages/Stories';
import MarketSentiment from '@/pages/MarketSentiment';
import Tokens from '@/pages/Tokens';
import Token from '@/pages/Token';
import Feed from '@/pages/Feed';
import Blurts from '@/pages/Blurts';
import Settings from '@/pages/Settings';
import Profile from '@/pages/Profile';
import Notifications from '@/pages/Notifications';
import Favorites from '@/pages/Favorites';
import FeedPage from '@/pages/FeedPage';
import XFeed from '@/pages/XFeed';
import NFTArt from '@/pages/NFTArt';
import Disclaimer from '@/pages/Disclaimer';
import Terms from '@/pages/Terms';
import Privacy from '@/pages/Privacy';
import PumpVision from '@/pages/PumpVision';
import Blog from '@/pages/Blog';
import BlogPost from '@/pages/BlogPost';
import GoogleTrends from '@/pages/GoogleTrends';
import { Toaster } from '@/components/ui/toaster';
import { OnboardingProvider } from '@/components/onboarding/OnboardingProvider';
import { GoogleAnalytics } from '@/components/GoogleAnalytics';

export default function App() {
  return (
    <ThemeProvider attribute="class" defaultTheme="dark" enableSystem={false} forcedTheme="dark">
      <OnboardingProvider>
        <Router>
          <div className="flex min-h-screen flex-col">
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="stories" element={<Stories />} />
                <Route path="market-sentiment" element={<MarketSentiment />} />
                <Route path="tokens" element={<Tokens />} />
                <Route path="token/:symbol" element={<Token />} />
                <Route path="pump-vision" element={<PumpVision />} />
                <Route path="favorites" element={<Favorites />} />
                <Route path="feed" element={<Feed />} />
                <Route path="blurts" element={<Blurts />} />
                <Route path="settings" element={<Settings />} />
                <Route path="profile" element={<Profile />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="bitcoin-news" element={<BitcoinNews />} />
                <Route path="news" element={<FeedPage />} />
                <Route path="market" element={<FeedPage />} />
                <Route path="search" element={<FeedPage />} />
                <Route path="x-feed" element={<XFeed />} />
                <Route path="nft-art" element={<NFTArt />} />
                <Route path="disclaimer" element={<Disclaimer />} />
                <Route path="terms" element={<Terms />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="admin" element={<Admin />} />
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:slug" element={<BlogPost />} />
                <Route path="google-trends" element={<GoogleTrends />} />
              </Route>
            </Routes>
          </div>
          <Toaster />
          <GoogleAnalytics />
        </Router>
      </OnboardingProvider>
    </ThemeProvider>
  );
}