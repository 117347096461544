import { create } from 'zustand';
import { XMLParser } from 'fast-xml-parser';
import { FEED_URLS } from '@/lib/constants/feeds';
import { useSettings } from '@/hooks/use-settings';
import { processRSSItem, fetchFeed } from '@/lib/utils/feed';

const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds
const BACKOFF_FACTOR = 1.5; // Exponential backoff factor

interface FeedState {
  feeds: Record<string, any[]>;
  isLoading: boolean;
  error: string | null;
  favorites: string[];
  lastUpdated: Record<string, number>;
  fetchFeeds: () => Promise<void>;
  toggleFavorite: (articleId: string) => void;
  startAutoRefresh: () => void;
  stopAutoRefresh: () => void;
}

async function fetchWithRetry(url: string, feedKey: string, retries = MAX_RETRIES): Promise<any[]> {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const items = await fetchFeed(url);
      console.log(`Successfully fetched ${feedKey} feed on attempt ${attempt + 1}`);
      return items;
    } catch (error) {
      const isLastAttempt = attempt === retries - 1;
      if (isLastAttempt) {
        console.error(`Failed to fetch ${feedKey} after ${retries} attempts:`, error);
        throw error;
      }
      
      // Exponential backoff
      const delay = RETRY_DELAY * Math.pow(BACKOFF_FACTOR, attempt);
      console.log(`Retrying ${feedKey} in ${delay}ms (attempt ${attempt + 1}/${retries})`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  return [];
}

export const useFeedStore = create<FeedState>((set, get) => {
  let refreshInterval: NodeJS.Timeout | null = null;

  const startAutoRefresh = () => {
    if (refreshInterval) return;
    
    refreshInterval = setInterval(() => {
      const lastUpdated = get().lastUpdated;
      const now = Date.now();
      
      // Check each feed individually for refresh
      Object.keys(lastUpdated).forEach(feedKey => {
        const lastUpdate = lastUpdated[feedKey];
        if (!lastUpdate || now - lastUpdate >= REFRESH_INTERVAL) {
          get().fetchFeeds();
        }
      });
    }, REFRESH_INTERVAL);
  };

  const stopAutoRefresh = () => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
      refreshInterval = null;
    }
  };

  return {
    feeds: {},
    isLoading: false,
    error: null,
    favorites: [],
    lastUpdated: {},
    
    fetchFeeds: async () => {
      set({ isLoading: true, error: null });
      try {
        const { enabledFeeds } = useSettings.getState();
        const currentFeeds = get().feeds;
        const currentLastUpdated = get().lastUpdated;
        
        const feedResults = await Promise.allSettled(
          enabledFeeds
            .filter(key => FEED_URLS[key as keyof typeof FEED_URLS])
            .map(async (key) => {
              const url = FEED_URLS[key as keyof typeof FEED_URLS];
              try {
                const items = await fetchWithRetry(url, key);
                return [key, items];
              } catch (error) {
                // Keep existing feed data on error
                console.warn(`Keeping existing data for ${key} due to fetch error`);
                return [key, currentFeeds[key] || []];
              }
            })
        );

        const now = Date.now();
        const feeds = feedResults.reduce((acc, result, index) => {
          const key = enabledFeeds[index];
          if (result.status === 'fulfilled') {
            acc[key] = result.value[1];
            currentLastUpdated[key] = now;
          } else {
            console.error(`Failed to fetch ${key}:`, result.reason);
            acc[key] = currentFeeds[key] || [];
          }
          return acc;
        }, {} as Record<string, any[]>);

        set({ 
          feeds, 
          isLoading: false,
          lastUpdated: currentLastUpdated
        });
      } catch (error) {
        console.error('Feed fetch error:', error);
        set({ 
          error: 'Failed to fetch feeds. Please try again later.',
          isLoading: false 
        });
      }
    },

    toggleFavorite: (articleId) =>
      set((state) => ({
        favorites: state.favorites.includes(articleId)
          ? state.favorites.filter((id) => id !== articleId)
          : [...state.favorites, articleId],
      })),

    startAutoRefresh,
    stopAutoRefresh
  };
});

// Start auto-refresh when the store is initialized
useFeedStore.getState().startAutoRefresh();

// Cleanup on window unload
window.addEventListener('unload', () => {
  useFeedStore.getState().stopAutoRefresh();
});